@use "src/styles/utils.scss" as *;

.LayoutWeb {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__contain {
    display: flex;
    flex-direction: column;
    &__children {
      margin-bottom: 3rem;
      &__title {
        margin-top: 3rem;
      }
    }
  }
}

@media (min-width: $break-point) {
  .LayoutWeb {
    &__contain {
      display: flex;
      flex-direction: row;
      margin-top: 90px;
      &__children {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0 0 1.5rem;
        &__title {
          text-align: left;
        }
      }
    }
  }
}
