@use "src/styles/utils.scss" as *;

.CampaignsCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__image {
    width: 200px;
    height: 200px;
    cursor: pointer;
  }
  &__text {
    width: max-content;
    padding: 15px 0;
    font-size: 16px;
    cursor: pointer;
  }
  &__buttons {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}

.cursor {
  cursor: pointer;
}

@media (min-width: $break-point) {
  .CampaignsCard {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }
}
