@use "src/styles/utils.scss" as *;

.navigate {
  position: fixed;
  top: $navBar-margin-top;
  height: $navbar-height;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $c-background-dark;
  max-width: 100%;
  width: 100%;
  color: #f3f2f2;
  font-family: arial;
  font-size: 14px;
  font-weight: 600;
  z-index: 1;

  ul {
    margin: 0;
    padding: 0;
  }

  ul {
    display: flex;
    list-style-type: none;
  }

  input {
    outline: none !important;
    border: none;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.3);
    color: #f3f2f2;
    border-radius: 4px;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__route {
    position: relative;
    top: 0.7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5vh 1.2vw 3vh 1.2vw;

    &-active {
      position: relative;
      top: 0.7vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5vh 1.2vw 3vh 1.2vw;
      text-decoration: none;
      background-color: #f3f2f2;
      border-radius: 4px;
      color: #2b363d !important;
      &:hover {
        text-decoration: none;
      }
    }

    &:focus,
    &:hover {
      text-decoration: none;
      background-color: #f3f2f2;
      border-radius: 4px;
      color: #2b363d;
    }
  }

  &__home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw 0 1vw;
    a:hover,
    a:focus {
      text-decoration: none !important;
      color: inherit !important;
    }
  }

  &__title {
    padding-left: 0.8vw;
    padding-bottom: 1.2vh;
    color: inherit;
    font-family: Arial;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vh;
  }

  &__link {
    &--icon {
      vertical-align: middle;
      height: 2.8vh;
      padding-right: 0.4vw;
    }

    &--logo {
      width: 4rem;
    }

    &--text {
      vertical-align: middle;
      font-size: 1.9vh;
    }
  }

  &__right {
    margin-right: 7.2vw;
    height: 100%;
  }

  &--user {
    position: absolute;
    right: 2%;
    // width: 45%;
    display: flex;
    justify-content: flex-end;

    &-circle1 {
      height: 80px;
      width: 80px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #f3f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      &-circle2 {
        height: 73px;
        width: 73px;
        background-color: #fff;
        border-radius: 50%;
        border: 2px solid $c-background-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        &-avatar {
          height: 73px;
          width: 73px;
          background-color: #fff;
          border-radius: 50%;
          border: 2px solid $c-background-dark;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-user {
          height: 40px;
          width: 40px;
          background-color: #fff;
          border-radius: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translateY(-4%);
          &:hover {
            transform: scale(115%) translateY(-4%);
          }
        }
      }
    }
  }
}
