@use "src/styles/utils.scss" as *;

.CatalogsContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-top: 1.5rem;
  margin-top: 3rem;
}

.CatalogsTitle h2{
  margin-bottom: 30px;
}

.Catalog-section_image-input {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;
  gap: 20px;
}

.Catalog-section_image-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
  input {
    justify-content: flex-end;
  }
}

@media (min-width: $break-point) {
  .CatalogsContent {
    flex-direction: row;
    align-items: flex-start;
    gap: 3rem;
    flex-wrap: wrap;
    margin: 0;
  }
  .CatalogsTitle {
    position: relative;
    text-align: left;
    margin-bottom: 30px;
    
    h2{
      margin: 0;
    }
  }

  .Catalog-section_image-title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 3rem;
    input {
      justify-content: flex-end;
    }
  }

  .Catalog-section_image-input {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 3rem;
    gap: 20px;
  }

  .catalogos-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    button {
      margin-right: 5rem;
    }
  }
}
