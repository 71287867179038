@use "src/styles/utils.scss" as *;

.buttonModalSave {
    @include button-dark;
    font-size: 12px;
    padding: 4px 30px;
    cursor: pointer;
    min-width: 100px;
    
}

.buttonModalCancel{
    @include button-light-sm;
    background-color: #b0b0b0;
    color: $c-text-dark;
    padding: 4px 24px;
    margin-left: 8px;
    font-size: 12px;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.consultant{
    &__modal{
        &__text{
            font-size: 14px;
            padding: 1px 0px;
            margin-bottom: 1.5rem;
        }
        &__button{
            width: 50px;
            font-size: 14px;
            padding: 0.1rem 1.5rem;
            margin-top: 0.5rem;
            margin-right: 1rem;
            margin-bottom: 1.5rem;
        }
        &__container{
            display: flex;
            flex-direction: column;
            &__select{
                width: 50%;
            }
            button{
                width: max-content;
            }
            p{
                margin-top: 2rem;
            }
        }
    }
}