@use "/src/styles/utils.scss" as *;

.displayContactsElements {
  display: none;
}
.notDisplayElements {
  display: block;
}

.createdAt {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $c-text-light;
  font-size: 9px;
}

.ContactCard__Card {
  background-color: #fff;
  margin: 16px 0px;
  padding: 24px 16px 16px 16px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.046) 0px 1px 4px;
  border: 1px solid rgba(0, 0, 0, 0.089);
  text-align: left;
  font-size: 12px;
  position: relative;
  z-index: 1;

  &--item {
    > h3 {
      margin: 0;
      margin-bottom: 8px;
      font-size: 16px;
      > a {
        text-decoration: none;
        color: inherit;
      }
    }
    > p {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    
    &--border {
      border: 1px solid rgba(0, 0, 0, 0.329);
      border-radius: 3px;
      padding: 0 16px;
    }
    
    &__tags {
      margin-top: 16px;
      margin-bottom: 0px;
      &__tag {
        @include tag;
        padding: 5px 8px;
      }
    }
  }
  .ContactCard__Card--item-cta {
    width: 100%;
    margin-top: 20px;
    text-align: right;

    &--button {
      @include button-dark;
      padding: 8px 12px;
      font-size: 11px;
    }
  }

}
@media (min-width: $break-point) {
  .displayContactsElements {
    display: block;
  }
  .notDisplayElements {
    display: none;
  }

  .ContactCard__Card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 182px;
  
    &--item {
      > h3 {
        margin-bottom: 8px;
        font-size: 24px;
      }
      > p {
        margin-bottom: 8px;
        font-size: 14px;
      }

      &__tags {
        margin: 10px 0px;
        &__tag {
          @include tag;
        }
      }
  
      > textarea {
        border: 1px solid rgba(0, 0, 0, 0.329);
        resize: none;
        border-radius: 3px;
        width: 100%;
        height: 107px;
  
        &:focus {
          border: 1px solid $c-background-dark;
          outline: none;
        }
      }
  
      &__tags {
        margin-top: 25px;
      }
    }
  
    .ContactCard__Card--item-genInfo {
      width: calc(30%);
    }
    .ContactCard__Card--item-iconsInfo {
      width: calc(26%);
    }
    .ContactCard__Card--item-textArea {
      width: calc(28%);
      border-left: 1px solid rgba(0, 0, 0, 0.329);
      padding-left: 16px;
    }
    .ContactCard__Card--item-cta {
      width: calc(15%);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 0;
  
      > span {
        color: $c-text-light;
      }

      &--button {
        @include button-dark;
        font-size: 12px;
      }
    }
  }
}
