.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  font-size: 12px;
  padding: 6px;
  border: 1px solid #dfdfdf !important;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #dfdfdf !important;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-thead > tr {
  border: 1px solid #dfdfdf;
  font-size: 12px !important;
}

.p-datatable .p-datatable-tbody > tr {
  border-bottom: 2px solid #dfdfdf;
  border-spacing: 75%;
}
.p-datatable {
  border: 1px solid #f0efef;
}
.p-datatable .p-datatable-tbody > tr > td {
  margin: 4px;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #f0efef;
  color: inherit;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #dfdfdf;
  background: #2b363d !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #2b363d !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #dfdfdf !important;
  background: #2b363d !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7d2fe;
  border-color: #2b363d !important;
}
