@use "src/styles/utils.scss" as *;

.Layout {
  &__content {
    position: fixed;
    top: calc($navbar-height + $navBar-margin-top + $subHeader-height);
    width: 100%;
    height: 70vh; 
    height: calc((var(--vh) * 100) - $navbar-height + $navBar-margin-top + $subHeader-height);
    overflow-y: auto;
    background-color: $c-background-light;
    padding: 0 16px;
  }

  &__content::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  
  &__content::-webkit-scrollbar-track {
    background: $c-background-mid; /* color of the tracking area */
  }
  
  &__content::-webkit-scrollbar-thumb {
    background-color: $c-dark-grey; /* color of the scroll thumb */
  }

}

.plusFooter {
  height: 100vh; 
  height: calc(
    (var(--vh) * 100) - $footer-height - $navbar-height - $navBar-margin-top - $subHeader-height
    ) !important;
}