@use "/src/styles/utils.scss" as *;

.Pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > button {
    border: 1px solid rgba(0, 0, 0, 0.219);
    border-radius: 1px;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    height: 25px;
  }
  > small {
    border: 1px solid rgba(0, 0, 0, 0.219);
    border-radius: 1px;
    padding: 3px 10px;
    height: 25px;

     &:hover {
      cursor: default;
     }
  }
}