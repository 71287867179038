@use "src/styles/utils.scss" as *;

.WebHomePage {
  width: 100%;
  &__title {
    font-size: 16px;
    text-align: center;
    &__bold {
      font-weight: bold;
    }
  }
  &__image {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: $break-point) {
  .WebHomePage {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    &__title {
      text-align: start;
      width: max-content;
    }
  }
}
