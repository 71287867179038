@use "src/styles/utils.scss" as *;

.HomePageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 1.5rem;
  padding-top: 1.5rem;
}
.ContactSection {
  width: 100%;
  &__title {
    font-size: 16px;
    text-align: center;
    &__bold {
      font-weight: bold;
    }
  }
  &__image {
    width: 200px;
    height: 200px;
  }
  &__item {
    text-align: center;
  }
}

@media (min-width: $break-point) {
  .HomePageContent {
    width: 25%;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HomePageTitle {
    position: relative;
    &__button {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 5rem;
    }
  }
  .HomePageCategories {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0;
  }
  .ContactSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    &__title {
      text-align: start;
      width: max-content;
    }
    &__item {
      text-align: left;
    }
  }
  .w50 {
    width: 50vw;
  }
}
