@use "src/styles/utils.scss" as *;

.LayoutMarketingCampaigns {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__contain {
    display: flex;
    flex-direction: column;
    &__children {
      margin-bottom: 3rem;
      &__title {
        margin-top: 3rem;
      }
      &__campaignSelect {
        padding: 0 15px;
      }
      &__contactsTable {
        padding: 0 15px;
        &__title {
          text-align: left;
          padding-bottom: 5px;
        }
      }
    }
  }
}

@media (min-width: $break-point) {
  .LayoutMarketingCampaigns {
    &__contain {
      display: flex;
      flex-direction: row;
      margin-top: 90px;
      &__children {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0 0 1.5rem;
        &__title {
          text-align: left;
        }
        &__campaignSelect {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          &:first-child {
            width: 35%;
          }
          &__search {
            width: 33%;
          }
        }
        &__contactsTable {
          width: 98%;
        }
      }
    }
  }
}
