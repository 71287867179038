@use "src/styles/utils.scss" as *;

.buttonForm {
  @include button-dark;
  font-size: 12px;
  padding: 4px 30px;
}

.buttonFormCancel {
  @include button-light-sm;
  background-color: #b0b0b0;
  color: $c-text-dark;
  padding: 4px 20px;
  margin-left: 8px;
}

.buttonFormDelete {
  @include button-dark;
  background-color: #aa2c2c;
  color: $c-text;
  font-size: 12px;
  padding: 4px 20px;
  margin-left: 8px;
}

.Input, .Checkboxes, .CheckBox, .Textarea {
  margin: 20px 0px;
}

.Textarea {
  margin-bottom: 40px;
}

.ContactForm {
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.046) 0px 1px 4px;
  border: 1px solid rgba(0, 0, 0, 0.089);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div{
      margin-bottom: 1.3rem;
    }
    &--info{
      margin-left: 0px;
      margin-bottom: 0.2rem;
      text-align: left;
      > p, h3, div {
        margin-bottom: 0.2rem;
      }
      > div{
        display: flex;
        align-items: center;
      }
    }
  }
}
@media (min-width: $break-point) {
.ContactForm {
  padding: 40px 32px;

  &__header {
    display: flex;
    flex-direction: row;
    gap: 1.3rem;
    margin-bottom: 40px;
    &--img {
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
        border-radius: 3px;
        background-color: #dfdfdf;
        position: relative;
        
        > img {
          width: 95%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
          border-radius: 3px;
        }
      }
    }

    &--info {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      text-align: left;

      > p {
        display: flex;
        align-content: center;
        align-items: center;
        margin-top: 10px;
      }
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--col {
      width: 46%;
    }
  }
}
}
