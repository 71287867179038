@use "src/styles/utils.scss" as *;

.CatalogsContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-top: 1.5rem;
  margin-top: 3rem;
}

.CatalogsTitle h1 {
  margin-bottom: 3rem;
}
.contactImage {
  width: 200px;
  height: 200px;
}

@media (min-width: $break-point) {
  .CatalogsContent {
    flex-direction: row;
    align-items: flex-start;
    gap: 3rem;
    flex-wrap: wrap;
    margin: 0;
  }
  .CatalogsTitle {
    position: relative;
    text-align: left;
    margin-bottom: 30px;
    &__button {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 5rem;
    }
    h1 {
      margin: 0;
    }
  }
}
