@use "src/styles/utils.scss" as *;

.marketingCampaignsNavbar {
  width: 100%;
  margin-top: 100px;
  &__menu {
    &__list {
      padding: 0 5px 15px 5px;
      margin: 0;
      display: flex;
      gap: 1.5rem;
      overflow-x: unset;
      text-align: left;
      list-style-type: none;
      & a {
        color: inherit;
        text-decoration: inherit;
      }
      & a:hover {
        font-weight: bold;
      }
      &__item {
        width: 100%;
      }
      & li {
        width: 100%;
      }
    }
  }
}

@media (min-width: $break-point) {
  .marketingCampaignsNavbar {
    width: 12%;
    height: 60vh;
    padding: 1rem 0 0 1.5rem;

    margin-top: 90px;

    &__menu {
      &__list {
        padding: 0 5px 15px 5px;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        overflow-x: unset;
        text-align: left;
        list-style-type: none;
        & a {
          color: inherit;
          text-decoration: inherit;
        }
        & a:hover {
          font-weight: bold;
        }
        &__item {
          width: 100px;
        }
      }
    }
  }
}
