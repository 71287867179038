@use "src/styles/utils.scss" as *;

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.buttonForm {
  @include button-dark;
  cursor: pointer;
  &--disabled {
    cursor: not-allowed;
    @include button-disabled;
    font-size: 12px;
    padding: 4px 30px;
  }
}

.MatchedAd__container {
  width: 100%;

  &__col {
    width: 100%;
    &--item:not(:first-child) {
      margin: 30px 0;
    }
    &--item {
      &-center {
        display: flex;
        align-items: center;
        > span {
          margin-left: 8px;
        }
      }
    }
  }

  &__col--left {
    width: 100%;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    margin-bottom: 12px;
    > h5 {
      background-color: #dddddd;
      padding: 8px;
      margin: 0;
      font-weight: 700;
    }
  }
}

.PopUp__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.527);
  animation: opacity 200ms;
  opacity: 1;
  display: flex;
  justify-content: center;
  padding-top: 5%;
  z-index: 99999;

  &__container {
    position: relative;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 3px;
    min-width: 40%;
    min-height: 40%;

    &--titleBanner {
      margin-top: 10px;
      position: relative;
      width: 100%;
      height: 30px;
      background-color: white;
      color: black;
      text-align: center;

      > h4 {
        line-height: 1.6;
        font-weight: bold;
      }

      &__close {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        font-weight: 500;
      }
    }

    &--titleBannerSimple {
      position: relative;
      width: 100%;
      text-align: center;

      &__closeSimple {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        font-weight: 500;
      }
    }

    &--content::-webkit-scrollbar {
      //display: none;
      width: 8px; /* width of the entire scrollbar */
    }

    &--content::-webkit-scrollbar-track {
      background: #fff; /* color of the tracking area */
    }

    &--content::-webkit-scrollbar-thumb {
      background-color: $c-dark-grey; /* color of the scroll thumb */
    }

    &--content {
      height: calc(100% - 40px);
      width: 100%;
      padding: 10px 30px;
      overflow: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }

    &--contentButtons::-webkit-scrollbar {
      display: none;
    }

    &--contentButtons {
      height: calc(100% - 40px - 50px);
      width: 100%;
      padding: 10px;
      overflow: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }

    &--fixedButtons {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(-50%);
      background-color: #fff;
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 30px;
    }
  }
}

@media (max-width: $break-point) {
  .PopUp__overlay {
    padding-top: 20%;
    &__container {
      min-width: 90%;
      min-height: 30%;

      &--content {
        padding: 10px 18px;
      }
    }
  }
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  height: 12%;
  width: 80%;
  padding: 5px;

  .buttonFormExit {
    font-size: 80%;
    text-align: center;
    margin-right: 4%;
    padding: 1% 8% !important;
    border: 1px solid #5c5c5c;
    border-radius: 3px;
    background-color: #fff;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: none;
      box-shadow: rgba(0, 0, 0, 0.032) 0px 3px 8px;
    }
  }

  .buttonFormSave {
    font-size: 80%;
    text-align: center;
    padding: 1% 6% !important;
    border: 1px solid #5c5c5c;
    border-radius: 3px;
    background-color: #2b363d;
    text-decoration: none;
    color: #fff;
    &:hover {
      text-decoration: none;
      box-shadow: rgba(0, 0, 0, 0.032) 0px 3px 8px;
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 4px;
    right: 0px;
    top: 0px;
    font-size: 24px;
    // background: #ffffff;
    color: #2b363d;
  }
}

.MatchedAd__container {
  width: 100%;

  &__col {
    width: 100%;
    &--item:not(:first-child) {
      margin: 30px 0;
    }
    &--item {
      &-center {
        display: flex;
        align-items: center;
        > span {
          margin-left: 8px;
        }
      }
    }
  }

  &__col--left {
    width: 100%;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    margin-bottom: 12px;
    > h5 {
      background-color: #dddddd;
      padding: 8px;
      margin: 0;
      font-weight: 700;
    }
  }

  .MatchedAd__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #dddddd;
    padding: 8px;
    margin: 0;
    > h5 {
      > p {
        font-weight: 700;
      }
    }
  }
  .AdCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__row {
      padding: 8px;
      flex-direction: row;
      &__title {
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 12px;
        text-decoration: none;
        color: inherit;
        > p {
          font-weight: bold;
        }
      }
      &__box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 20%;
        padding: 8px;
        margin-left: 12px;
      }
    }
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    font-size: 12px;
    padding: 6px;
    border: 1px solid #dfdfdf !important;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #dfdfdf !important;
    transition: box-shadow 0.2s;
  }
  .p-datatable .p-datatable-thead > tr {
    border: 1px solid #dfdfdf;
    font-size: 12px !important;
  }

  .p-datatable .p-datatable-tbody > tr {
    border-bottom: 2px solid #dfdfdf;
    border-spacing: 75%;
  }
  .p-datatable {
    border: 1px solid #f0efef;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    margin: 4px;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #f0efef;
    color: inherit;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #dfdfdf;
    background: #2b363d !important;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #2b363d !important;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #dfdfdf !important;
    background: #2b363d !important;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #2b363d !important;
  }
}

@media (min-width: $break-point) {
  .popup-content {
    background: rgb(255, 255, 255);
    height: 10%;
    width: 40%;
    padding: 5px;

    .buttonFormExit {
      font-size: 80%;
      text-align: center;
      margin-right: 2%;
      padding: 2% 12% !important;
      border: 1px solid #5c5c5c;
      border-radius: 3px;
      background-color: #fff;
      text-decoration: none;
      color: inherit;
      &:hover {
        text-decoration: none;
        box-shadow: rgba(0, 0, 0, 0.032) 0px 3px 8px;
      }
    }

    .buttonFormSave {
      font-size: 80%;
      text-align: center;
      padding: 2% 8% !important;
      border: 1px solid #5c5c5c;
      border-radius: 3px;
      background-color: #2b363d;
      text-decoration: none;
      color: #fff;
      &:hover {
        text-decoration: none;
        box-shadow: rgba(0, 0, 0, 0.032) 0px 3px 8px;
      }
    }

    .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 4px;
      right: 0px;
      top: 0px;
      font-size: 24px;
      // background: #ffffff;
      color: #2b363d;
    }
  }
  .MatchedAd__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    &__col {
      width: 46%;
    }

    &__col--left {
      width: 28%;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      // padding: 12px;
      > h5 {
        background-color: #dddddd;
        padding: 8px;
        margin: 0;
        font-weight: 700;
      }
    }
    &__col--right {
      width: 70%;
    }
  }
}
