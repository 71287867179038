@use "/src/styles/utils.scss" as *;

.Input {
  text-align: left;
  width: 100%;
  > label {
    display: block;
    font-size: 14px;
    margin-bottom: 15px;
  }

  &-input {
    padding-left: 8px;
    border-left: 2px solid $c-background-dark;
    > input {
      background-color: #fff;
      border: none;
      outline: none;
      border-bottom: 1px solid #b0b0b0;
      width: 100%;
      padding-left: 5px;

      &::placeholder {
        font-size: 12px;
        color: #bebebe;
      }
    }
    & input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &-error {
    border-left: 2px solid $c-red;
  }

  &-errors {
    padding-left: 10px;
    color: $c-red;
  }
}
