@use "src/styles/utils.scss" as *;

.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  background-color: #2b363d;

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    margin-top: 5%;
    height: 75vh;
    width: 80vw;

    border-radius: 10px;
    background-color: #2b363d;

    &-logo {
      position: relative;
      top: 15%;
      color: #ffff;
      width: 15rem;
    }

    &-form {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &-field {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &-title {
      margin: 0 10%;
      font-size: 24px;
      font-weight: bolder;
      align-self: flex-start;
      color: white;
    }

    &-text {
      align-self: center;
      width: 80%;
      margin: 1vh 0vw;
      font-size: 18px;
      font-family: Arial;

      border: 1.5px solid #ffff;
      border-radius: 12px;
      padding: 4px 8px 4px 8px;

      &:focus {
        font-size: 18px;
        border: 1.5px solid #2b363d;
      }
    }
  }

  &__submit {
    padding: 1.5% 8.5%;
    background-color: #2b363d;
    border-radius: 4px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-weight: bold;
    font-size: large;

    &:hover {
      box-shadow: 0px 0px 6px white;
      transform: scale(102%);
      cursor: pointer;
    }
    &--logout {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2% 4%;

      &:hover {
        box-shadow: 0px 0px 6px white;
        transform: scale(102%);
        cursor: pointer;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8%;
    min-width: 30vw;
    position: relative;
    bottom: 1%;

    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    color: #e5e5e5;

    &-logo {
      padding-left: 0.5vw;
      width: 8rem;
    }
  }
}

@media (min-width: $break-point) {
  .login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #2b363d;

    &__box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      max-width: 40vw;
      height: 75vh;
      width: 40vw;

      border-radius: 10px;
      background-color: #2b363d;

      &-logo {
        color: #ffff;
        transform: translateY(-35%);
        width: 15rem;
      }

      &-form {
        width: 80%;
        display: flex;
        flex-direction: column;
      }

      &-field {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      &-title {
        margin: 0 10%;
        font-size: 24px;
        font-weight: bolder;
        align-self: flex-start;
        color: white;
      }

      &-text {
        align-self: center;
        width: 80%;
        margin: 1vh 0vw;
        font-size: 18px;
        font-family: Arial;

        border: 1.5px solid #ffff;
        border-radius: 12px;
        padding: 4px 8px 4px 8px;

        &:focus {
          font-size: 18px;
          border: 1.5px solid #2b363d;
        }
      }
    }

    &__submit {
      padding: 1.5% 8.5%;
      background-color: #2b363d;
      border-radius: 4px;
      border: 2px solid #ffffff;
      color: #ffffff;
      font-weight: bold;
      font-size: large;
      &:hover {
        box-shadow: 0px 0px 6px white;
        transform: scale(102%);
        cursor: pointer;
      }

      &--logout {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2% 4%;
        &:hover {
          box-shadow: 0px 0px 6px white;
          transform: scale(102%);
          cursor: pointer;
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2%;
      min-width: 30vw;

      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      color: #e5e5e5;

      &-logo {
        padding-left: 0.5vw;
        width: 8rem;
      }
    }
  }
}
