@use "src/styles/utils.scss" as *;

.subHeader {
  position: relative;
  top: calc($navbar-height + $navBar-margin-top);
  height: $subHeader-height;
  background-color: $c-background-light;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: $all-margins;

  &__filter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid $c-background-light;

    &--item {
      padding: 0.5rem 2.5rem;
      border-radius: 2px;
      border: none;
      background-color: #dfdfdf;

      &__border {
        padding: 0.5rem 0px;

        & p {
          align-self: center;
          border-radius: 0;
          margin: 0;
          padding: 0 2.5rem;
          border-left: 0.7px solid #5c5c5c;
          border-right: 0.7px solid #5c5c5c;
        }

        &__active {
          color: #ffffff;
          background: #2b363d;
          cursor: pointer;
        }
      }

      &__active {
        border: none;
        padding: 0.5rem 2.5rem;
        border-radius: 2px;
        color: #ffffff;
        background: #2b363d;
        cursor: pointer;
      }
    }

    &--item:hover {
      text-decoration: none;
      color: #ffffff;
      background: #2b363d;
      cursor: pointer;
    }
  }

  &__title {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 8px;
    display: flex;
    align-items: flex-end;

    > h2 {
      font-size: 24px;
      font-weight: 700;
    }

    > p {
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-weight: bold;
    }
  }

  &__subtitle {
    width: 100%;
    position: fixed;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 12px;

    &-item {
      width: 100%;
      margin-right: 3rem;
    }

    &_searchBarContainer {
      position: relative;

      .pi-search {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
      }

      .p-inputtext {
        padding-left: 25px; 
      }
    }
  }

  &__btn {
    @include button-dark-sm;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 10rem;
  }
}

@media (min-width: $break-point) {
  .subHeader {
    &__subtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: static;

      &_searchBarContainer {
        position: relative;

        .pi-search {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: #999;
        }

        .p-inputtext {
          padding-left: 25px;
        }
      }
    }
  }
}

@media (max-width: $break-point) {
  .subHeader {
    display: flex;
    justify-content: center;
    top: calc($subHeader-height - 15px);
    height: calc($navbar-height + $navBar-margin-top + 15px);
  }

  .noDisplay {
    display: none;
  }
}
