@use "/src/styles/utils.scss" as *;

@keyframes opacity {
  0%   {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.PopUp__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.527);
  animation: opacity 200ms;
  opacity: 1;
  display: flex;
  justify-content: center;
  padding-top: 5%;
  z-index: 99999;

  &__container {
    position: relative;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 3px;
    min-width: 40%;
    min-height: 40%;

    &--titleBanner {
      margin-top: 10px;
      position: relative;
      width: 100%;
      height: 30px;
      background-color: $c-background-dark;
      color: #fff;
      text-align: center;

      > h4 {
        line-height: 1.6;
      }

      &__close {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        font-weight: 500;
      }
    }

    &--titleBannerSimple {
      position: relative;
      width: 100%;
      text-align: center;

      &__closeSimple {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        font-weight: 500;
      }
    }

    &--content::-webkit-scrollbar {
      //display: none;
      width: 8px; /* width of the entire scrollbar */
    }
    
    &--content::-webkit-scrollbar-track {
      background: #fff; /* color of the tracking area */
    }
    
    &--content::-webkit-scrollbar-thumb {
      background-color: $c-dark-grey; /* color of the scroll thumb */
    }

    &--content {
      height: calc(100% - 40px);
      width: 100%;
      padding: 10px 30px;
      overflow: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }

    &--contentButtons::-webkit-scrollbar {
      display: none;
    }
    
    &--contentButtons {
      height: calc(100% - 40px - 50px);
      width: 100%;
      padding: 10px;
      overflow: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }

    &--fixedButtons {
      position: absolute;
      bottom:0;
      left: 0;
      transform: translateY(-50%);
      background-color: #fff;
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 30px;
    }
  }
}

@media (max-width: $break-point) {
  .PopUp__overlay {
    padding-top: 20%;
    &__container {
      min-width: 90%;
      min-height: 30%;

      &--content {
        padding: 10px 18px;
      }
    }
  }
}
