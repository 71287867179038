@use "src/styles/utils.scss" as *;

.CategoriesImagesContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-top: 1.5rem;

  &__title {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    width: max-content;
  }
  &__image {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: $break-point) {
  .CategoriesImagesContent {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: -1.5rem;

    &__title {
      text-align: start;
      width: 100%;
      margin-left: 3rem;
    }
  }
}
