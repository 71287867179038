// Colors
$c-text: #fff;
$c-text-dark: rgb(48, 40, 40);
$c-text-light: #C8C8C8;
$c-background-dark: #2B363D;
$c-background-light: #F3F2F2;
$c-background-mid: #DFDFDF;
$c-dark-grey: #5C5C5C;
$c-red: #C83000;
$c-green: #228400;

// Sizes
$break-point: 880px;
$navbar-height: 7.4vh;
$navBar-margin-top: 1.4vh;
$subHeader-height: 100px;
$footer-height: 7.4vh;
$all-margins: 16px;
$buttons-searchbars: 30px; 

