@use "src/styles/utils.scss" as *;

.GoBack {
  @include button-light-sm;
  width: max-content;
  background-color: #dfdfdf;
  color: #5c5c5c;
  &:hover {
    transform: scale(102%);
  }
}
