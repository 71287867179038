@use "src/styles/utils.scss" as *;

.ServicesEditForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 230px;
  // left: auto;
  // right: auto;
  margin: -8rem auto auto auto;
  gap: 3rem;
  background-color: #fff;
  padding: 2rem 0 2rem 0;
  z-index: 99;
  &__title {
    display: flex;
    align-items: center;
    gap: 3rem;
    &__close {
      position: absolute;
      right: 10px;
      font-size: 40px;
      cursor: pointer;
    }
  }

  

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center,
    
  }
  
  textarea {
    resize: none;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem 0;
  }
}

.yearInput {
  border: none;
  border-bottom: 1px solid #000;
  width: 50%;
  text-align: center;
  &:focus {
    outline: none;
  }
}

@media (min-width: $break-point) {
  .ServicesEditForm {
    width: 85%;
    right: 50px;
    top: 210px;
    display: flex;
    // padding-top: 12rem;
    margin: -12rem auto auto auto;
    // justify-content: center;
    // align-items: center;
    &__title {
      &__close {
        right: 20px;
      }
    }
  }
  .yearInput {
    width: 10%;
  }
}


.Services__box{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80rem;
  gap: 15px;
  
  &__item {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    textarea {
      resize: none;
    }
  }
}