@use "src/styles/utils.scss" as *;

body {
  margin: 0;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: $c-background-light !important;
  p {
    margin: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
