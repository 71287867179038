@use "src/styles/utils.scss" as *;

.MarketingCampaignNewForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: auto;
  right: auto;
  margin: auto;
  gap: 3rem;
  padding-top: 1.5rem;
  background-color: #fff;
  padding: 0 0 2rem 0;
  z-index: 9;
  min-height: 100vh;
  &__title {
    display: flex;
    align-items: center;
    gap: 3rem;
    &__close {
      position: absolute;
      right: 20px;
      font-size: 40px;
      cursor: pointer;
    }
  }
  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & textarea {
      width: 70%;
      resize: none;
      padding: 8px;
      &:focus {
        outline: 0;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem 0;
  }
}

.yearInput {
  border: none;
  border-bottom: 1px solid #000;
  width: 70%;
  text-align: center;
  &:focus {
    outline: none;
  }
}

.error {
  font-size: 12px;
  padding-top: 3px;
  color: #6d0303;
  border: none;
}

@media (min-width: $break-point) {
  .MarketingCampaignNewForm {
    width: 85%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    &__item {
      & textarea {
        width: 50%;
      }
    }
  }
  .yearInput {
    width: 50%;
  }
}
