@use "src/styles/utils.scss" as *;

.Textarea {
  text-align: left;
  > label {
    display: block;
    font-size: 14px;
    margin-bottom: 15px;
  }

  &-input {
    padding-left: 8px;
    border-left: 2px solid $c-background-dark;
    > textarea {
      background-color: #fff;
      border: none;
      outline: none;
      border-bottom: 1px solid #B0B0B0;
      border-top: 1px solid #B0B0B0;
      width: 100%;
      padding-left: 5px;
      min-height: 80px;
      resize: vertical;
      &::placeholder {
        font-size: 12px;
        color: #bebebe;
      }
    }
  }
}

.error {
  border-left: 2px solid $c-red;
}

.errors {
  padding-left: 10px;
  color: $c-red;
  text-align: left;
}