@use "src/styles/utils.scss" as *;

@import url("https://unpkg.com/primeicons/primeicons.css");
@import url("https://unpkg.com/primereact/resources/themes/lara-light-indigo/theme.css");
@import url("https://unpkg.com/primereact/resources/primereact.min.css");
@import url("https://unpkg.com/primeflex@2.0.0/primeflex.min.css");

/* AccordionDemo.css */
.surfaceBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.accordion-demo .accordion-custom i,
.accordion-demo .accordion-custom span {
  vertical-align: middle;
}

.accordion-demo .accordion-custom span {
  margin: 0 0.5rem;
}

.accordion-demo .p-accordion p {
  line-height: 1.5;
  margin: 0;
}

.buttonFormSave {
  @include button-dark;
  font-size: 12px;
  padding: 4px 20px;
  cursor: pointer;
  &__disabled {
    //@include button-disabled;
    cursor: not-allowed;
    pointer-events: none;
    font-size: 12px;
    padding: 3px 20px;
  }
}

.buttonFormCancel {
  @include button-light-sm;
  background-color: #b0b0b0;
  color: $c-text-dark;
  padding: 4px 20px;
  margin-left: 8px;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &__disabled {
    //@include button-disabled;
    cursor: not-allowed;
    pointer-events: none;
    font-size: 12px;
    color: $c-text-dark;
    padding: 6px 20px;
    background-color: #b0b0b0;
    border-radius: 2px;
    margin-left: 8px;
    border: solid 0.5px #767676;
  }
}

.buttonFormDelete {
  @include button-dark;
  background-color: #aa2c2c;
  color: $c-text;
  font-size: 12px;
  padding: 4px 20px;
  margin-left: 8px;
  &__disabled {
    //@include button-disabled;
    cursor: not-allowed;
    color: $c-text;
    pointer-events: none;
    background-color: #aa2c2c;
    border-radius: 2px;
    font-size: 12px;
    padding: 4.5px 20px;
    margin-left: 8px;
    opacity: 0.8;
    border: solid 0.5px #aa2c2c;

  }
}

.buttonGoWeb{
  @include button-dark;
  font-size: 12px;
  padding: 5px 15px;
  cursor: pointer;
  margin-top: -2px;
}

.adForm {
  &__filter-box {
    display: flex;
    align-items: center;
    font-size: 12px;
    border-radius: 4px;
    margin-top: 0.1rem;
    background-color: none;
    button:first-child{
      padding: 4px 15px 4px 15px;
      width: max-content;
    }
    &__container{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a:link, a:active, a:visited, a:focus {
        text-decoration: none;
        color: #ffffff;
      } 
    }

    &--item {
      @include button-light-sm;
      padding: 4px 32px 4px 32px;
      border-radius: 4px;
      border: 1px solid $c-background-dark;
      margin-right: 8px;

      &__active {
        // border: 1px solid $c-background-light;
        padding: 4px 32px 4px 32px;
        border-radius: 4px;
        color: #ffffff;
        background: #2b363d;
        cursor: pointer;
        border: 1px solid $c-background-dark;
        margin-right: 8px;
      }
    }
    & button:focus,
    button:hover {
      text-decoration: none;
      color: #ffffff;
      background: #2b363d;
      cursor: pointer;
    }
  &__subHeader{
    &__options{
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: space-between;
    }
  }
  }
}

@media (min-width: $break-point) {
  .adForm {
    &__filter-box{
      margin-left: 32px !important;
    }
    &__subHeader{
      &__options{
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: space-between;
      }
    }
  }

  .buttonGoWeb{
    @include button-dark;
    font-size: 12px;
    padding: 5px 15px;
    cursor: pointer;
    margin-top: -2px;
  }
}