@use "src/styles/utils.scss" as *;

.CatalogNewForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: auto;
  right: auto;
  margin: auto;
  gap: 1.5rem;
  padding-top: 1.5rem;
  background-color: #fff;
  padding: 0 0 2rem 0;
  &__title {
    display: flex;
    align-items: center;
    gap: 3rem;
    &__close {
      position: absolute;
      right: 0;
      font-size: 40px;
      cursor: pointer;
    }
  }
  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem 0;
  }
}

.yearInput {
  border: none;
  border-bottom: 1px solid #000;
  width: 50%;
  text-align: center;
  &:focus {
    outline: none;
  }
}

@media (min-width: $break-point) {
  .CatalogNewForm {
    width: 85%;
    display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .yearInput {
    width: 20%;
  }
}
