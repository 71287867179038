@use "/src/styles/utils.scss" as *;

.displayElements {
  display: none;
}
.notDisplayElements {
  display: block;
}

.createdAt {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $c-text-light;
  font-size: 9px;
}

.ConsultantCard {
  background-color: #fff;
  margin: 16px 0px;
  padding: 18px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.046) 0px 1px 4px;
  border: 1px solid rgba(0, 0, 0, 0.089);

  > div {
    text-align: left;
  }

  &--info {
    &--title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      > h3 {
        margin: 0;
        margin-bottom: 8px;
        font-size: 20px;
      }

      &--link {
        color: $c-text-dark;
        &:hover {
          color: $c-text-dark;
        }
      }

      &--tag {
        @include tag-medium;
        margin-left: 10px;
        &:hover {
          text-decoration: none;
        }
      }
    }

    > p {
      margin-bottom: 8px;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }

  &--location {
    p {
      margin-bottom: 4px;
      font-size: 14px;
    }

    &--box > p:first-child {
      margin-left: 8px;
      padding-left: 14px;
      margin-top: -22px;
    }

    &--box > p:last-child {
      margin-left: 8px;
      padding-left: 14px;
      border-left: 1px solid #2b363d;
    }
  }

  &--interact {
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;

    &--button {
      @include button-dark;
      padding: 4px 10px;
      font-size: 11px;
      &:hover {
        color: #fff;
      }
    }
  }
}

@media (min-width: $break-point) {
  .displayElements {
    display: contents;
  }
  .notDisplayElements {
    display: none;
  }

  .ConsultantCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    height: 160px;

    &--img {
      width: 15%;
      // padding-right: 20px;
      height: 100%;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 140px;
        border-radius: 3px;
        background-color: #dfdfdf;
        position: relative;

        > img {
          width: 95%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          min-height: 100%;
          min-width: 100%;
          max-height: 100%;
          max-width: 100%;
          object-fit: cover;
          border-radius: 3px;
        }
      }
    }

    &--info {
      width: 45%;
      &--title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
      }
    }

    &--location {
      width: 30%;
      p {
        margin-bottom: 8px;
        font-size: 14px;
        display: flex;
        align-items: center;
      }

      &--top {
        display: flex;
      }

      &--box > p:first-child {
        margin-left: 0px;
        padding-left: 0px;
        margin-top: 0px;
      }

      &--box > p:last-child {
        margin-left: 0px;
        padding-left: 0px;
        border-left: none;
      }

      &--box {
        margin-left: 8px;
        padding-left: 14px;
        border-left: 1px solid #2b363d;
        margin-top: 0px;
      }
    }

    &--interact {
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      > span {
        color: $c-text-light;
      }

      &--button {
        @include button-dark;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
