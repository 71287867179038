@use "src/styles/utils.scss" as *;

.LayoutMarketingCampaigns {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__contain {
    display: flex;
    flex-direction: column;
    &__children {
      margin-bottom: 3rem;
      &__title {
        margin-top: 3rem;
      }
    }
  }
  &__button {
    // position: absolute;
    // right: 0;
    // top: 19vh;
    // margin-right: 5rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: $break-point) {
  .LayoutMarketingCampaigns {
    &__contain {
      display: flex;
      flex-direction: row;
      margin-top: 90px;
      &__children {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0 0 1.5rem;
        &__title {
          text-align: left;
        }
        &__campaigns {
          display: flex;
          flex-wrap: wrap;
          gap: 2rem;
        }
      }
    }
    &__button {
      position: absolute;
      right: 0;
      top: 15vh;
      margin-right: 5rem;
    }
  }
}
