@use "src/styles/utils.scss" as *;

.DetailsAds__container {
  width: 100%;

  &__col {
    width: 100%;
    &__web{
      &__button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        a:link, a:active, a:visited, a:focus {
          text-decoration: none;
          color: #ffffff;
        } 
      }
    }

    &--item:not(:first-child) {
      margin: 30px 0;
    }
    &__featured{
      &__checks{
        display: flex;
        gap: 2rem;
        margin-top: -1rem;
      }
    }

    > label {
      display: block;
      font-size: 14px;
      text-align: left;
      margin: 16px 0;
      padding-bottom: 16px;
      border-bottom: 1px solid #b0b0b0;
    }

    &--qualities {
      width: 100%;
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 6px 0px;
        width: 90%;
      }
    }
    &--item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &-center {
        display: flex;
        align-items: center;
        > span {
          margin-left: 8px;
        }
      }
    }
  }
}

.buttonGoWeb{
  @include button-dark;
  font-size: 12px;
  padding: 5px 15px;
  cursor: pointer;
  margin-top: -2px;
  text-decoration: none;
}

@media (min-width: $break-point) {
  .DetailsAds__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    &__col {
      width: 48%;
      &--qualities {
        width: 100%;
        padding-left: 8px;
        margin-right: 50px;

        display: flex;
        flex-direction: column;
        > div {
          margin: 6px 0px;
          width: 100%;
        }
      }
    }
  }
}
