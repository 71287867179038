@use "src/styles/utils.scss" as *;

.p-datatable-resizable .p-resizable-column .p-sortable-column .p-highlight {
  background-color: rgba(133, 132, 132, 0.151) !important;
  border: 1px solid rgba(182, 177, 177, 0.774) !important;
  color: $c-dark-grey !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: rgba(133, 132, 132, 0.151) !important;
  border: 1px solid rgba(182, 177, 177, 0.774) !important;
  color: $c-dark-grey !important;
}

.pi-sort-amount-down:before,
.pi-sort-amount-up-alt:before {
  color: $c-dark-grey !important;
}

.p-datatable .p-sortable-column:focus.p-highlight:not(.p-sortable-disabled):focus,
.p-datatable .p-sortable-column:focus {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper::-webkit-scrollbar {
  //display: none;
  height: 10px !important; /* width of the entire scrollbar */
}

.p-datatable-responsive-scroll > .p-datatable-wrapper::-webkit-scrollbar-track {
  background-color: $c-background-mid !important; /* color of the tracking area */
}

.p-datatable-responsive-scroll > .p-datatable-wrapper::-webkit-scrollbar-thumb {
  background-color: $c-dark-grey !important; /* color of the scroll thumb */
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  outline-color: #999999;
  > a {
    color: $c-dark-grey;
    text-decoration: underline rgba(22, 22, 22, 0.247);
  }
}

.tbl {
  display: flex;
  flex-direction: column;
  margin: 1vh 1vw;

  & th {
    box-sizing: border-box;
    border: 1px solid black;
    background-color: #e5e5e5;
    text-align: end;
  }

  & td {
    box-sizing: border-box;
    border: 1px solid black;
    background-color: white;
  }

  &__status {
    max-width: 12vw;
    min-width: 12vw;
    background-color: #e5e5e5;
  }

  &__creationDate {
    max-width: 8vw;
    min-width: 8vw;
    align-items: center;
    border: 1px solid black;
  }
  &__reference {
    max-width: 6vw;
    min-width: 6vw;
  }
  &__direction {
    max-width: 10vw;
    min-width: 10vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__buildingType {
    max-width: 8vw;
    min-width: 8vw;
  }
  &__adType {
    max-width: 8vw;
    min-width: 8vw;
  }
  &__price {
    max-width: 12vw;
    min-width: 12vw;
    &-row {
      max-width: 6vw;
      min-width: 6vw;
    }
  }
  &__buildSurface {
    max-width: 12vw;
    min-width: 12vw;
  }
  &__title {
    max-width: 10vw;
    min-width: 10vw;
  }
  &__closeOperation {
    max-width: 10vw;
    min-width: 10vw;
  }
  &__contact {
    max-width: 12vw;
    min-width: 12vw;
  }
  &__consultant {
    max-width: 12vw;
    min-width: 12vw;
  }
}
